import React from 'react'
import { Authenticator } from 'aws-amplify-react'
import Auth from '@aws-amplify/auth'
import Layout from '../components/layout'
import { isLoggedInFromLocalstorage } from '../utils/auth'
import { navigate } from '@reach/router';
import _ from 'lodash';

const Login = props => {
  

  return (
  <Layout {...props} title="Login">
    <Authenticator
      onStateChange={authState => {
        if (authState === 'signedIn') {
          navigate('/');
        } else if (authState === 'signIn') {
          // logout();
        }
      }}
    />
  </Layout>
)};

export default Login;